import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    border: `1px solid #EEEEEE`,
    justifyContent: "flex-start",
    height: "100%",
    maxWidth: "100%",
    minWidth: "100%",
    paddingTop: 24,
    paddingBottom: 40,
  },
  tabs: {
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      justifyContent: "flex-start",
    },
    "& .MuiTab-textColorInherit": {
      opacity: 1,
    },
  },
}));

const RoutedVerticalTabs = ({
  indexCallback,
  Tabvalue,
  tabData,
  selectedIndex,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    indexCallback(newValue);
  };
  useEffect(() => {
    if (Tabvalue) {
      setValue(Tabvalue);
    }
  }, [Tabvalue]);
  return (
    <div data-qaid='verticaltabs_main_div' className={classes.root}>
      <Tabs
        data-qaid='verticaltabs_tabs'
        className={classes.tabs}
        orientation='vertical'
        variant='scrollable'
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}>
        {tabData.map((item, index) => (
          <Tab
            data-qaid='verticaltabs_tab'
            key={item.id}
            style={{
              color: "#004C60",
              backgroundColor: selectedIndex === index ? "#C8E6F1" : "#FAFAFA",
              fontSize: 14,
              fontFamily: "AvenirLT-Medium",
              paddingLeft: 40,
            }}
            label={item.name}
          />
        ))}
      </Tabs>
    </div>
  );
};
export default RoutedVerticalTabs;
