import { Button, Grid } from "@material-ui/core";
import React, { useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import NoPermissionPage from "../../components/NoPermission";
import MyContext from "../../Provider/MyContext";
import { AvenirBlackH4, H6 } from "../../utils/text";
import { fetchAllItems } from "../../redux/actions";

const Menu = () => {
  const dispatch = useDispatch();
  const Context = useContext(MyContext);
  const userPermissions = useSelector(
    (state) => state.loginReducer.userPermissions
  );
  const location = useLocation();
  const navigate = useNavigate();

  const menulist = [
    {
      key: "menu_items",
      title: Context.langData.categories,
      route: "menu/categories",
    },
    {
      key: "order_types",
      title: Context.langData.settings,
      route: "menu/settings",
    },
  ];

  useEffect(() => {
    dispatch(fetchAllItems());
  }, [dispatch]);

  const isItemHasPermission = useMemo(
    () =>
      menulist.map((item) => {
        const permission = userPermissions.find(
          (perm) => perm.key === item.key
        );
        return {
          ...item,
          isPermission: permission?.status || false,
        };
      }),
    [userPermissions, menulist]
  );

  const selectedMenu = isItemHasPermission.find((item) => {
    if (location.pathname === "/home/menu") return true;
    const routeRegex = new RegExp(`^/home/${item.route}(\\/|$)`);
    return routeRegex.test(location.pathname);
  });

  if (!selectedMenu?.isPermission) {
    return <NoPermissionPage />;
  }

  const handleNavigation = (route) => {
    if (route.includes("settings")) {
      navigate(`/home/${route}/item-catalogue`);
    } else {
      navigate(`/home/${route}`);
    }
  };

  return (
    <Grid
      style={{
        backgroundColor: "#FFFFFF",
        minHeight: "100%",
        width: "100%",
        overflow: "hidden",
      }}>
      {/* Header Section */}
      <Grid style={{ height: "20%", marginBottom: 28 }}>
        <Grid item xs={12} style={{ marginLeft: 36 }}>
          <H6
            label={Context.langData.menu}
            style={{ color: "#051D33", paddingTop: 36, fontWeight: "800" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ marginLeft: 36, marginTop: 36, display: "flex" }}>
          {isItemHasPermission.map((item, i) => (
            <Button
              key={item.route}
              style={{
                backgroundColor: location.pathname.includes(item.route)
                  ? "#051D33"
                  : "#EEEEEE",
                borderRadius: i === 0 ? "4px 0 0 4px" : "0 4px 4px 0px",
              }}
              onClick={() => handleNavigation(item.route)}>
              <AvenirBlackH4
                label={item.title}
                style={{
                  color: location.pathname.includes(item.route)
                    ? "#FFFFFF"
                    : "#051D33",
                  padding: "10px 16px",
                  lineHeight: "24px",
                }}
              />
            </Button>
          ))}
        </Grid>
      </Grid>

      {/* Render Selected View */}
      <Grid style={{ height: "80%" }}>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default Menu;
