import React, { useEffect, useState, useContext } from "react";
import { Grid, Button, IconButton } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAddonCategories,
  UnassignAddonToItems,
} from "../../../redux/actions";
import MyContext from "../../../Provider/MyContext";
import CloseCross from "../../../components/SVG/CloseCross";
import { AvenirRedH4, AvenirBlackH4, H4, H6 } from "../../../utils/text";
import styles from "../../../styles/index";
import { MultiSelectChip, CenterModal } from "../../../components";

const ItemUnassignAddons = ({ open, onClose, items, callback }) => {
  const style = styles();
  const dispatch = useDispatch();
  const addonCategories = useSelector((state) => state.menu.addonCategories);
  const Context = useContext(MyContext);
  const [assignedAddonCategories, setAssignedAddonCategories] = useState([]);
  const [selectedAddonCategories, setSelectedAddonCategories] = useState([]);
  const [errorFlag, setError] = useState(null);

  useEffect(() => {
    dispatch(fetchAddonCategories());
    const assignedAddonCategoryIdNames = getAssignedAddonCategoryIdNames();
    const assignedAddonCategoryIds = assignedAddonCategoryIdNames.map(
      (cat) => cat.id
    );
    setAssignedAddonCategories(assignedAddonCategoryIdNames);
    setSelectedAddonCategories(assignedAddonCategoryIds);
  }, []);

  const getAssignedAddonCategoryIdNames = () => {
    const assignedAddonCategories = [];
    addonCategories.forEach((category) => {
      if (doesSelectedItemsContainAddonCategory(category.addon_cat_name)) {
        const vobj = {
          id: category.addon_cat_id,
          name: category.addon_cat_name,
        };
        assignedAddonCategories.push(vobj);
      }
    });
    return assignedAddonCategories;
  };

  const doesSelectedItemsContainAddonCategory = (addonCategoryName) => {
    let contains = false;
    items?.forEach((item) => {
      item?.modifiers.forEach((modifier) => {
        if (modifier?.modifier_name === addonCategoryName) {
          contains = true;
        }
      });
    });
    return contains;
  };

  const handleUnassign = () => {
    // if (selectedAddonCategories.length < 1) {
    //   setError(Context.langData.assign_addon_error);
    //   return;
    // }

    const reqItems = [];

    items.map((p) => {
      const obj = {};
      obj.id = p.item_id;
      obj.name = p.name;
      reqItems.push(obj);
    });

    const reqAddon = selectedAddonCategories.map((item) => ({
      addon_cat_id: item,
    }));
    const request = {
      modifiers: reqAddon,
      items: reqItems,
    };

    dispatch(UnassignAddonToItems(request, callback));
  };

  const handleCategoryChange = (value) => {
    setError("");
    setSelectedAddonCategories(value);
  };

  const onClear = () => {
    setSelectedAddonCategories([]);
  };
  return (
    <CenterModal open={open} padding={1}>
      <Grid
        data-qaid='assignCategories__body_grid'
        style={{
          display: "flex",
          height: "75vh",
          flexDirection: "column",
          width: 700,
        }}>
        <Grid
          data-qaid='assignCategories__child1_grid'
          style={{
            display: "flex",
            height: "95%",
            flexDirection: "column",
            overflowX: "auto",
            borderRadius: "5px",
          }}>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            }}>
            <Grid>
              <H6
                data-qaid='assignCategories__title'
                style={{ margin: "20px 36px 20px 36px" }}
                label={
                  items.length > 1
                    ? Context.langData.unAssign_addon_multiple.replace(
                        // eslint-disable-next-line no-template-curly-in-string
                        "${itemNum}",
                        items.length
                      )
                    : Context.langData.unAssign_addon_single
                }
              />
            </Grid>
            <Grid data-qaid='assignCategories__close_grid'>
              <IconButton onClick={onClose} style={{ marginRight: 36 }}>
                <CloseCross data-qaid='assignCategories__closeicon' size={20} />
              </IconButton>
            </Grid>
          </Grid>
          <H4
            data-qaid='assignCategories__desc'
            style={{ marginLeft: 36, marginBottom: 20, marginRight: 36 }}
            label={Context.langData.unAssign_addon_desc}
          />
          <Grid
            container
            direction='column'
            style={{
              marginLeft: 36,
              marginBottom: 40,
              marginRight: 36,
              width: "90%",
            }}>
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
              }}>
              <AvenirBlackH4
                label={Context.langData.modifier_group}
                style={{ width: "90" }}
              />
              {selectedAddonCategories.length > 0 && (
                <IconButton
                  data-qaid='clearLabel'
                  style={{
                    backgroundColor: "white",
                    paddingRight: "3px",
                  }}
                  variant='label'
                  onClick={onClear}>
                  <AvenirBlackH4
                    data-qaid='clearLabel'
                    label={Context.langData.clear}
                    style={{
                      color: "#007191",
                      backgroundColor: "white",
                    }}
                  />
                </IconButton>
              )}
            </Grid>
            <MultiSelectChip
              list={assignedAddonCategories}
              value='id'
              name='name'
              placeholder={Context.langData.select_one}
              selectedValue={selectedAddonCategories}
              handleChange={handleCategoryChange}
              border={false}
              height={56}
              width='100%'
            />
          </Grid>
        </Grid>
        <Grid
          data-qaid='assignCategories_cancel_main_Grid'
          style={{
            display: "flex",
            height: "10%",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}>
          <Grid
            data-qaid='assignCategories_cancelGrid'
            item
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              backgroundColor: "#FAFAFA",
              width: "100%",
              paddingTop: "25px",
              alignItems: "center",
              paddingBottom: "25px",
              paddingLeft: "16px",
              border: "1px solid #C3C3C3",
            }}>
            <AvenirRedH4
              data-qaid='assignCategory_request_errorHeading'
              label={errorFlag}
            />
            <Button
              className={style.buttonClass}
              data-qaid='assignCategories_cancelButton'
              style={{
                backgroundColor: "#FFF",
                border: "2px solid #051D33",
                marginRight: 20,
                marginLeft: 20,
                width: "45%",
              }}
              variant='contained'
              id='cancel'
              onClick={onClose}>
              <AvenirBlackH4
                data-qaid='assignCategories_cancelLabel'
                label={Context.langData.cancel}
              />
            </Button>
            <Button
              className={style.buttonClass}
              data-qaid='assignCategories_saveButton'
              style={{
                backgroundColor:
                  selectedAddonCategories.length === 0 ? "grey" : "#AB1212",
                color: "white",
                marginRight: 30,
                width: "45%",
              }}
              disabled={selectedAddonCategories.length === 0}
              variant='contained'
              id='cancel'
              type='submit'
              onClick={handleUnassign}>
              <AvenirBlackH4
                data-qaid='assignCategories_saveLabel'
                label={Context.langData.Unassign_modifier_groups}
                style={{ color: "white" }}
              />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </CenterModal>
  );
};

export default ItemUnassignAddons;
