import { Button, Grid, makeStyles, Box } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AlertDialog from "../../../../components/Dialog";
import RightDrawer from "../../../../components/RightDrawer";
import SnackBar from "../../../../components/SnackBar";
import CircleClose from "../../../../components/SVG/CircleClose";
import CopyIcon from "../../../../components/SVG/Copy";
import DeleteIcon from "../../../../components/SVG/Delete";
import MenuBookIcon from "../../../../components/SVG/Menu";
import MyContext from "../../../../Provider/MyContext";
import { AvenirBlackH4, H4 } from "../../../../utils/text";
import { parseToFloat, ConvertPrice } from "../../../../utils/util";
import {
  copyItemById,
  createItemsByCatId,
  deleteItemsByCatId,
  editItemsByCatId,
  fetchItemInfoById,
  fetchItemsByCatId,
  fetchAllCategories,
} from "../../../../redux/actions";
import SingleSelect from "../../../../components/SingleSelect";
import Basic from "./basic";
import EditComboSelection from "./editComboSelection";

const useStyles = makeStyles({
  inputText: {
    fontSize: 14,
    color: "#051D33",
    fontFamily: "AvenirLT",
  },
  h: {
    height: "100%",
  },
});
const CombosModal = ({
  open,
  onClose,
  cat_id,
  itemId = null,
  callback,
  deleteItemCallback,
  copyItemCallback,
  editCallback,
}) => {
  const [selectedOrderTypes, setSelectedOrderTypes] = useState([]);
  const ordertypes = useSelector((state) => state.metaReducer.ordertypes);
  const classes = useStyles();
  const Context = useContext(MyContext);
  const dispatch = useDispatch();
  const [comboIndex, setComboIndex] = useState(-1);
  const [showDialog, setShowDialog] = useState(false);
  const [bodyMessage] = useState(
    Context.langData.are_you_sure_you_want_to_delete_this_item
  );
  const [heading] = useState(Context.langData.delete_item_header);
  const [snackbar, setSnackbar] = useState(null);
  const [catId, setCatId] = useState(cat_id || "");
  const [advanced, setAdvanced] = useState({
    variablePrice: [],
    attributes: [],
  });
  const [basicInfo, setBasicInfo] = useState({
    name: "",
    price: "",
    isActive: 1,
    ordertypes: [],
    desc: "",
    img_url: "",
    combos: [
      { combo_selection_name: "Combo selection 1", combo_selection_items: [] },
      { combo_selection_name: "Combo selection 2", combo_selection_items: [] },
    ],
    bg_colour: "#FFFFFF",
    font_colour: "#000000",
    button_style: 1,
  });
  const [combo, setCombo] = useState(null);
  const [comboNameValidate, setComboNameValidate] = useState("validate");
  const [comboPriceValidate, setComboPriceValidate] = useState("validate");
  const [comboselectionValidate, setComboselectionValidate] = useState(true);
  const categories = useSelector((state) => state.menu.categories);

  // console.log("BASIC INFO", basicInfo)
  // console.log("ADVANCED", advanced)
  useEffect(() => {
    if (!itemId) {
      const otypes = ordertypes.map((ot) => ot.otype_id);
      setBasicInfo({ ...basicInfo, ordertypes: otypes });
    }
  }, [ordertypes, itemId]);
  useEffect(() => {
    if (itemId) {
      dispatch(fetchItemInfoById({ item_id: itemId }, fetchItemByIdCallBack));
    } else {
      const otypeIds = ordertypes.map((otype) => otype.otype_id);
      setSelectedOrderTypes(otypeIds);
    }
  }, [itemId, ordertypes]);
  useEffect(() => {
    dispatch(fetchAllCategories());
  }, []);
  const fetchItemByIdCallBack = (response) => {
    const otypes = ordertypes.map((ot) => ot.otype_id);
    const basic = {
      name: response.name,
      price: ConvertPrice(response.base_price),
      isActive: response.g_availability,
      ordertypes: otypes,
      desc: response.description,
      img_url: response.img_url,
      combos: response.combo,
      bg_colour: response.bg_colour || "#FFFFFF",
      font_colour: response.font_colour || "#000000",
      button_style: response.button_style || 0,
    };
    const selOtypes = [];
    if (response?.prices_availability?.length > 0) {
      response.prices_availability.map((p) => {
        if (p.availability) {
          selOtypes.push(p.otype_id);
        }
      });
    }
    setSelectedOrderTypes(selOtypes);
    setBasicInfo(basic);
    setTimeout(() => {
      response.prices_availability.map((pa) => {
        pa.price[0] = (pa.price[0] - parseToFloat(response.base_price)).toFixed(
          2
        );
      });
      setAdvanced({
        variablePrice: response.prices_availability,
        attributes: [],
        tax_mode: response.tax_mode,
      });
    }, 500);
  };
  useEffect(() => {
    const priceAvailability = [];
    ordertypes.map((ot) => {
      const index = selectedOrderTypes.findIndex((e) => e === ot.otype_id);
      const adIndex = advanced.variablePrice.findIndex(
        (e) => e.otype_id === ot.otype_id
      );

      const price =
        adIndex !== -1
          ? parseToFloat(advanced.variablePrice[adIndex].price)
          : 0;

      if (index !== -1) {
        priceAvailability.push({
          otype_id: ot.otype_id,
          otype_name: ot.otype_name,
          availability: true,
          price,
        });
      }
    });
    if (
      JSON.stringify(advanced.variablePrice) !==
      JSON.stringify(priceAvailability)
    )
      setAdvanced({
        ...advanced,
        variablePrice: priceAvailability,
      });
  }, [
    ordertypes,
    basicInfo.ordertypes,
    advanced.variablePrice,
    selectedOrderTypes,
  ]);
  const onSetBasicInfo = (info) => {
    let f = true;
    setBasicInfo(info);
    info.combos.map((c) => {
      if (c.combo_selection_items.length === 0) {
        f = false;
      }
    });
    setComboselectionValidate(f);
  };
  const onSaveItem = () => {
    setComboNameValidate(basicInfo.name);
    setComboPriceValidate(basicInfo.price);
    const priceAvailability = advanced.variablePrice.map((ot) => ({
      otype_id: ot.otype_id,
      availability: true,
      packaging_charges: [0],
      price: [
        ot.price
          ? Math.round(
              parseToFloat(ot.price + parseToFloat(basicInfo.price)) * 100
            ) / 100
          : parseToFloat(basicInfo.price),
      ],
    }));
    const combos = [...basicInfo.combos];
    let f = true;
    combos.map((c) => {
      if (c.combo_selection_items.length === 0) {
        f = false;
      }
      c.combo_selection_items.map((ci) => {
        ci.serving_id = parseInt(ci.serving_id, 10);
        ci.price = parseToFloat(ci.price) || 0;
        delete ci.name;
      });
    });
    setComboselectionValidate(f);
    const obj = {
      cat_id: catId,
      item_id: itemId,
      g_availability: basicInfo.isActive,
      is_alcohol: 0,
      description: basicInfo.desc,
      image_url: basicInfo.img_url,
      name: basicInfo.name,
      base_price: parseToFloat(basicInfo.price),
      prices_availability: priceAvailability,
      combo: basicInfo.combos,
      addons: [],
      isVariablePackagingCharge: false,
      dietary: advanced.attributes,
      dietary_attributes: null,
      serving: ["base"],
      pricing_type: "simple",
      comments: [],
      bg_colour: basicInfo.bg_colour,
      font_colour: basicInfo.font_colour,
      button_style: basicInfo.button_style,
      item_type: "combo",
      tax_mode: advanced.tax_mode,
    };
    if (basicInfo.name !== "" && basicInfo.price !== "" && f) {
      if (itemId) {
        dispatch(editItemsByCatId(obj, onEditCallback));
      } else {
        dispatch(createItemsByCatId(obj, onCreateCallback));
      }
    }
  };
  const onEditCallback = (res) => {
    if (res) {
      editCallback();
    }
    dispatch(fetchItemsByCatId({ filterBy: `category,${catId}` }));
    setTimeout(() => {
      onClose();
    }, [200]);
  };
  const onCreateCallback = (res) => {
    if (res) {
      callback();
    }
    dispatch(fetchItemsByCatId({ filterBy: `category,${catId}` }));
    setTimeout(() => {
      onClose();
    }, [200]);
  };
  const addComboItems = (combo, index) => {
    setCombo(combo);
    setComboIndex(index);
  };
  const onSaveComboSelection = (comboItem) => {
    let info = {};
    if (comboIndex !== -1) {
      info = {
        ...basicInfo,
        combos: [
          ...basicInfo.combos.slice(0, comboIndex),
          comboItem,
          ...basicInfo.combos.slice(comboIndex + 1),
        ],
      };
    } else {
      info = {
        ...basicInfo,
        combos: [...basicInfo.combos, comboItem],
      };
    }
    setBasicInfo(info);
    let f = true;
    // setBasicInfo(info)
    info.combos.map((c) => {
      if (c.combo_selection_items.length === 0) {
        f = false;
      }
    });
    setComboselectionValidate(f);
    setTimeout(() => {
      setCombo(null);
      setComboIndex(-1);
    }, 100);
  };
  const commonCallback = () => {
    fetchItemsByCatId({ filterBy: `category,${catId}` });
    onClose();
  };
  const deleteItem = (res) => {
    if (itemId) setShowDialog(false);
    if (res) {
      deleteItemCallback();
    }
    commonCallback();
  };
  const handleClose = (val) => {
    if (val) {
      dispatch(deleteItemsByCatId([itemId], deleteItem));
    }
    setShowDialog(false);
  };

  // eslint-disable-next-line no-unused-vars
  const deleteCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.item_delete_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.item_delete_failed,
        severity: "error",
      });
    }
    commonCallback();
  };

  const onCopy = () => {
    if (itemId)
      dispatch(
        copyItemById(
          {
            item_id: itemId,
          },
          (res) => {
            if (res) {
              copyItemCallback();
              fetchItemsByCatId({ filterBy: `category,${catId}` });
              onClose();
            }
          }
        )
      );
  };
  const show = combo == null;
  return (
    <RightDrawer open={open} onClose={onClose}>
      <Grid
        container
        style={{
          minWidth: 500,
          maxWidth: 500,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}>
        <Grid container style={{ width: "100%" }}>
          <Grid
            container
            data-qaid='create_item_container'
            style={{ height: 60, padding: "0px 24px" }}
            justify='space-between'
            alignItems='center'>
            <Grid item xs={6} className={classes.h}>
              <Grid
                container
                justify='flex-start'
                alignItems='center'
                className={classes.h}>
                <MenuBookIcon
                  data-qaid='assignkitchen_setting_icon'
                  style={{ marginRight: 10 }}
                  width={22.5}
                  height={24}
                  color='#707272'
                />
                <H4
                  data-qaid='assignkitchen_settings'
                  label={Context.langData.menu_combo}
                  style={{ color: "#707272" }}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.h}>
              <Grid
                container
                justify='flex-end'
                alignItems='center'
                className={classes.h}>
                <CircleClose
                  data-qaid='assignkitchen_closeicon'
                  size={20}
                  onClick={onClose}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            style={{
              height: window.innerHeight - 190,
              overflow: "auto",
              paddingBottom: 80,
            }}>
            {/* {isBasic ? ( */}
            <>
              <Box style={{ padding: "24px 24px 0px 24px", width: "100%" }}>
                <AvenirBlackH4 label={Context.langData.category} />
                <SingleSelect
                  id='cat_id'
                  data-qaid='single_select_category'
                  list={categories}
                  value={catId}
                  valueKey='cat_id'
                  displayKey='name'
                  placeholder={Context.langData.select_category}
                  handleChange={(e) => {
                    setCatId(e.target.value);
                  }}
                  border={false}
                  height={56}
                />
              </Box>
              <Basic
                basicInfo={basicInfo}
                ordertypes={ordertypes}
                selectedOrderTypes={selectedOrderTypes}
                setSelectedOrderTypes={setSelectedOrderTypes}
                addComboItems={addComboItems}
                setBasicInfo={(info) => onSetBasicInfo(info)}
                comboNameValidate={comboNameValidate}
                comboPriceValidate={comboPriceValidate}
                comboselectionValidate={comboselectionValidate}
                onBlurNameCallback={() => setComboNameValidate(basicInfo.name)}
                onBlurPriceCallback={() =>
                  setComboPriceValidate(basicInfo.price)
                }
                advanced={advanced}
                setAdvanced={setAdvanced}
              />
            </>
          </Grid>
          <Grid
            container
            style={{
              position: "absolute",
              height: 60,
              backgroundColor: "white",
              borderTop: "1px solid #EEE",
              justifyContent: "flex-end",
              bottom: 0,
              left: 0,
              alignItems: "center",
              zIndex: 999,
            }}>
            <Grid item xs={12} md={show ? 9 : 6}>
              <Grid container justify='flex-end'>
                {itemId && (
                  <>
                    <Button
                      startIcon={<DeleteIcon />}
                      onClick={() => setShowDialog(true)}>
                      <AvenirBlackH4
                        label='Delete'
                        style={{ color: "#004C60" }}
                      />
                    </Button>
                    <Button
                      onClick={onCopy}
                      style={{ marginLeft: 20, marginRight: 20 }}
                      startIcon={<CopyIcon />}>
                      <AvenirBlackH4
                        label='Copy'
                        style={{
                          textDecoration: "underline",
                          color: "#004C60",
                        }}
                      />
                    </Button>
                  </>
                )}
                <Button
                  style={{ border: "2px solid #707272" }}
                  onClick={onClose}>
                  <AvenirBlackH4 label='Cancel' />
                </Button>
                <Button
                  variant='contained'
                  style={{
                    backgroundColor: "#FFB600",
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                  onClick={onSaveItem}>
                  <AvenirBlackH4 label='Save' />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {combo !== null && (
          <EditComboSelection
            open={combo !== null}
            combo={combo}
            onSave={onSaveComboSelection}
            onClose={() => {
              setCombo(null);
              setComboIndex(-1);
            }}
          />
        )}
      </Grid>
      {showDialog && (
        <AlertDialog
          data-qaid='categories_alertDialog'
          showDialog={showDialog}
          body={bodyMessage}
          heading={heading}
          IsError={false}
          showExtra={false}
          handleClose={handleClose}
        />
      )}
      {snackbar && (
        <SnackBar
          data-qaid='taxeslist_snackbar'
          open
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
    </RightDrawer>
  );
};
export default CombosModal;
