import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  Grid,
  Button,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tabs,
  Tab,
} from "@material-ui/core";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import { useLDClient } from "launchdarkly-react-client-sdk";
import styles from "../../../styles/index";
import {
  RightDrawer,
  InputText,
  Dialog as AlertDialog,
  Position,
  SnackBar,
  PositionItemCard,
  DraggableList,
} from "../../../components";
import MyContext from "../../../Provider/MyContext";
import { AvenirBlackH4, H4, H8, DotTextMedium } from "../../../utils/text";
import {
  CircleClose,
  PluseIcon,
  Back as BackIcon,
  DownVector,
  BetaIcon,
  FullColorIndicator,
} from "../../../components/SVG";
import { FeatureKeys } from "../../../enums";
import {
  fetchItemsByCatId,
  deleteItemsByCatId,
  createItemPosition,
  fetchImageUrl,
  putImageUrl,
  itemImageUpload,
  fetchItemTypes,
  fetchAllCategories,
} from "../../../redux/actions";

import PizzasModal from "./pizzas";
import CreateItem from "./create/CreateItem";
import CombosModal from "./combos";
import ItemCard from "./ItemCard";
import EditCategory from "./editCategory";

const Icon = BetaIcon;
const Items = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAlcohol, setAlcohol] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const style = styles();
  const [openCreate, toggleCreateDrawer] = useState(false);
  const [openEdit, toggleEditDrawer] = useState(false);
  const [openSetPosition, togglePositionDrawer] = useState(false);
  const [openAddon, toggleAddonDrawer] = useState(false);
  const [itemId, setItemId] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const Context = useContext(MyContext);
  const [isDeleteAllItems, setIsDeleteAllItems] = useState(false);
  const [catName, setCatName] = useState("");
  const catItems = useSelector((state) => state.menu.category_items);
  const [items, setItems] = useState([]);
  const itemTypes = useSelector((state) => state.menu.itemTypes);
  const [isOpenComboModal, toggleComboModal] = useState(false);
  const [isOpenPizzaModal, togglePizzaModal] = useState(false);
  const [openCategoryEdit, toggleCategoryEditDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isSort, setIsSort] = useState(false);
  useEffect(() => {
    const { cat_id } = state;
    const { name } = state;
    setCatName(name);
    dispatch(fetchItemsByCatId({ filterBy: `category,${cat_id}` }));
    dispatch(fetchItemTypes());
  }, [state]);
  useEffect(() => {
    if (catItems) {
      const indexedItems = _.orderBy(catItems.items, ["position"], ["asc"]);
      setItems(indexedItems);
    }
  }, [catItems]);
  const itemtypeName = [
    { id: 1, value: "Individual food item", isBeta: false },
    { id: 2, value: "Alcoholic beverage", isBeta: false },
    { id: 3, value: "Combos", isBeta: false },
    { id: 4, value: "Build-your-own item", isBeta: true },
  ];

  // check if build your own item is available for the restaurant which is controlled by feature flag
  const featureFlagClient = useLDClient();
  if (featureFlagClient) {
    const flagValue = featureFlagClient.variation(
      FeatureKeys.BuildYourOwnItem,
      true
    );
    if (flagValue === false) {
      // Remove build your own item from itemtypeName
      const byo = (element) => element.id === 4;
      const index = itemtypeName.findIndex(byo);
      itemtypeName.splice(index, 1);
    }
  }

  const findItem = useMemo(() => {
    const filtered = itemTypes?.filter((e) => e.status === 1);
    const filterIds = filtered.map((i) => i.item_type_id);
    return itemtypeName.filter((obj) => filterIds.includes(obj.id));
  }, [itemTypes]);

  const handleChange = (id) => {
    setItemId(null);
    if (id === 1) {
      omMenuItemSelect(false);
    } else if (id === 2) {
      omMenuItemSelect(true);
    } else if (id === 3) {
      toggleComboModal(true);
      handleCloseMenu();
    } else if (id === 4) {
      togglePizzaModal(true);
      handleCloseMenu();
    }
  };
  const deleteCallback = () => {
    setSnackbar({
      message: Context.langData.item_delete_succ,
      severity: "success",
    });
    closingDrawer();
  };
  const createCallback = () => {
    setItemId(null);
    setSnackbar({
      message: Context.langData.item_create_succ,
      severity: "success",
    });
    closingDrawer();
  };
  const editCallback = () => {
    setItemId(null);
    setSnackbar({
      message: Context.langData.item_edit_succ,
      severity: "success",
    });
    closingDrawer();
  };
  const deleteItemCallback = (isSuccess) => {
    setItemId(null);
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.item_delete_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.item_delete_failed_combo,
        severity: "error",
      });
    }
    closingDrawer();
  };
  const copyItemCallback = () => {
    setItemId(null);
    setSnackbar({
      message: Context.langData.item_copy_succ,
      severity: "success",
    });
    closingDrawer();
  };
  const closingDrawer = () => {
    setItemId(null);
    toggleCreateDrawer(false);
    toggleEditDrawer(false);
    toggleAddonDrawer(false);
    togglePositionDrawer(false);
    const { cat_id } = state;
    dispatch(fetchItemsByCatId({ filterBy: `category,${cat_id}` }));
  };
  const addonCallback = () => {
    setSnackbar({
      message: Context.langData.item_addon_create_succ,
      severity: "success",
    });
    closingDrawer();
  };
  const positionCallback = () => {
    setSnackbar({
      message: Context.langData.item_position_succ,
      severity: "success",
    });
    closingDrawer();
  };
  const imageUploadCallback = () => {
    setSnackbar({
      message: Context.langData.item_image_succ,
      severity: "success",
    });
    closingDrawer();
  };

  const enableEditDrawer = (item) => {
    const itemId = item.item_id;
    setItemId(itemId);
    toggleCreateDrawer(false);
    if (item.item_type === "Combo") {
      toggleComboModal(true);
    } else if (item.item_type === "Byo") {
      togglePizzaModal(true);
    } else {
      toggleEditDrawer(true);
    }

    toggleAddonDrawer(false);
  };
  const categoryCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.cat_edit_succ,
        severity: "success",
      });
      toggleCategoryEditDrawer(false);
      dispatch(fetchAllCategories());
    } else {
      setSnackbar({
        message: Context.langData.category_edit_failed,
        severity: "error",
      });
    }
  };

  const handleClose = (val) => {
    if (val) {
      if (isDeleteAllItems) {
        const itemsArray = [];
        if (items && items.length > 0) {
          // eslint-disable-next-line no-unused-expressions
          items &&
            items.map((itemObj) => {
              itemsArray.push(itemObj.item_id);
            });
        }
        if (itemsArray.length > 0) {
          dispatch(
            deleteItemsByCatId(JSON.stringify(itemsArray), deleteCallback)
          );
        }
      }
    }
    setShowDialog(false);
  };
  const completeLogo = (response, file, itemId) => {
    const obj = {};
    const url = response.upload_url.split("?")[0];
    obj.ContentType = file.type;
    obj.Body = file;
    dispatch(
      putImageUrl(
        response.upload_url,
        obj,
        () => uploadImageUrl(url, itemId),
        response.storage === "azure"
      )
    );
  };
  const uploadImageUrl = (url, itemId) => {
    dispatch(
      itemImageUpload({ img_url: url, item_id: itemId }, imageUploadCallback)
    );
  };
  const uploadImageCallBack = (file, itemId) => {
    const fileName = file.name.split(".");
    const imageName = `${fileName[0] ? fileName[0] : "MenuImage"}_${moment()
      .unix()
      .toString()}.png`;
    dispatch(
      fetchImageUrl(
        {
          file_name: imageName,
          content_type: file.type,
          image_loc: "menu",
        },
        (response) => completeLogo(response, file, itemId)
      )
    );
  };
  const deleteAllItems = () => {
    setIsDeleteAllItems(true);
    setShowDialog(true);
  };
  const handleBackClick = () => {
    navigate("/home/menu/categories");
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const deleteCallBack = () => {
    setSnackbar({
      message: Context.langData.cat_delete_succ,
      severity: "success",
    });
    toggleCategoryEditDrawer(false);
    navigate("/home/menu/categories");
  };
  const omMenuItemSelect = (isAl) => {
    toggleCreateDrawer(true);
    handleCloseMenu();
    setAlcohol(isAl);
  };
  const closeCreateItem = () => {
    togglePizzaModal(false);
    toggleComboModal(false);
    setItemId(null);
    const { cat_id } = state;
    dispatch(fetchItemsByCatId({ filterBy: `category,${cat_id}` }));
  };
  const handleDragEnd = (startIndex, endIndex) => {
    const reorderedItems = Array.from(items);
    const [removed] = reorderedItems.splice(startIndex, 1);
    reorderedItems.splice(endIndex, 0, removed);
    const finalReorder = reorderedItems.map((item, index) => ({
      ...item,
      position: index,
    }));
    setItems(finalReorder);
  };

  const renderItem = (item) => (
    <PositionItemCard
      name={item.name}
      status={
        item.available ? Context.langData.active : Context.langData.inactive
      }
      date={item.last_updated_on}
    />
  );

  const handleSavePositionMetaData = () => {
    const array = [];
    items.map((item) => {
      array.push([item.item_id, item.position]);
    });
    dispatch(
      createItemPosition(array, () => {
        setIsSort(false);
      })
    );
  };

  const handleSort = () => {
    setIsSort(true);
    const positionIndexed = items.map((obj, index) => ({
      ...obj,
      position: index,
    }));
    setItems(positionIndexed);
  };

  return (
    <Grid justify='flex-start' style={{ backgroundColor: "#FFFFFF" }}>
      <Grid style={{ backgroundColor: "#FFFFFF", height: "100%" }}>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginRight: 32,
            paddingTop: 40,
            overflow: "hidden",
          }}>
          <Grid
            item
            data-qaid='categories_labelView'
            style={{
              flexDirection: "row",
              alignItems: "center",
              display: "flex",
              paddingLeft: 32,
            }}>
            {!isSort && (
              <BackIcon
                data-qaid='categories_editBack_icon'
                onClick={() => handleBackClick()}
                style={{ cursor: "pointer" }}
              />
            )}
            <Grid
              style={{
                flexDirection: "row",
                alignItems: "center",
                display: "flex",
                paddingLeft: 20,
              }}>
              <FullColorIndicator color={state.bg_colour} />
              <DotTextMedium
                data-qaid='categories_editBackLabel'
                label={catName}
                style={{ fontWeight: "bold", marginLeft: 5, fontSize: 24 }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              // justifyContent: "flex-end",
              alignItems: "center",
            }}>
            {!isSort && (
              <Button
                className={style.buttonClass}
                data-qaid='categories_creategrid'
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "white",
                  marginRight: 10,
                }}
                variant='text'
                id='cancel'
                onClick={() => deleteAllItems()}>
                <CircleClose
                  size={24}
                  data-qaid='categories_createpluse'
                  style={{ marginRight: 5 }}
                />
                <AvenirBlackH4
                  data-qaid='categories_createlabel'
                  style={{ color: "#004C60" }}
                  label={Context.langData.delete_all_items}
                />
              </Button>
            )}
            {!isSort && (
              <Button
                className={style.buttonClass}
                data-qaid='categories_sort'
                style={{
                  backgroundColor: "#00B6C9",
                  color: "white",
                  marginRight: 10,
                }}
                variant='contained'
                id='cancel'
                onClick={handleSort}>
                <AvenirBlackH4
                  data-qaid='categories_sort'
                  label={Context.langData.sort}
                />
              </Button>
            )}
            {isSort && (
              <>
                <Button
                  className={style.buttonClass}
                  data-qaid='categories_cancel'
                  style={{
                    backgroundColor: "#FFF",
                    color: "white",
                    marginRight: 10,
                    border: "2px solid #051D33",
                    height: 40,
                    width: 103,
                  }}
                  variant='contained'
                  id='cancel'
                  onClick={() => {
                    dispatch(
                      fetchItemsByCatId({
                        filterBy: `category,${state.cat_id}`,
                      })
                    );
                    setIsSort(false);
                  }}>
                  <AvenirBlackH4
                    data-qaid='categories_cancel'
                    label={Context.langData.cancel}
                  />
                </Button>
                <Button
                  className={style.buttonClass}
                  data-qaid='categories_save'
                  style={{
                    backgroundColor: "#FFB600",
                    color: "white",
                    marginRight: 10,
                    height: 40,
                    width: 90,
                  }}
                  variant='contained'
                  id='cancel'
                  onClick={() => {
                    handleSavePositionMetaData(items);
                  }}>
                  <AvenirBlackH4
                    data-qaid='categories_save'
                    label={Context.langData.save}
                  />
                </Button>
              </>
            )}
            {!isSort && (
              <>
                <Button
                  className={style.buttonClass}
                  data-qaid='categories_creategrid'
                  style={{
                    backgroundColor: "#00B6C9",
                    color: "white",
                    marginRight: 10,
                  }}
                  variant='contained'
                  id='cancel'
                  onClick={() => toggleCategoryEditDrawer(true)}>
                  <AvenirBlackH4
                    data-qaid='categories_createlabel'
                    label={Context.langData.manage}
                  />
                </Button>
                <Button
                  className={style.buttonClass}
                  data-qaid='categories_creategrid'
                  style={{
                    backgroundColor: "#FFB600",
                    color: "white",
                    marginRight: 10,
                  }}
                  variant='contained'
                  id='cancel'
                  onClick={(event) => setAnchorEl(event.currentTarget)}>
                  <PluseIcon
                    data-qaid='categories_createpluse'
                    style={{ marginRight: 5 }}
                  />
                  <AvenirBlackH4
                    data-qaid='categories_createlabel'
                    label={Context.langData.Create_new}
                  />
                </Button>
              </>
            )}
            <Menu
              id='simple-menu'
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl) && findItem?.length > 0}
              onClose={handleCloseMenu}>
              {findItem.map((item) => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                  onClick={() => handleChange(item.id)}>
                  {item.value}
                  <span width='20'>&nbsp;&nbsp;</span>
                  {item.isBeta && (
                    <Icon color='#051d33' width={36} height={18} />
                  )}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        </Grid>
        <Grid
          data-qaid='categories__border_grid'
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            border: "1px solid #EEEEEE",
            marginTop: 34,
            minHeight: ".1%",
          }}
        />
        <Grid
          item
          xs={12}
          style={{
            marginLeft: 32,
            marginRight: 32,
            marginTop: 56,
            overflowX: "hidden",
            // eslint-disable-next-line no-undef
            height: window.innerHeight - 180,
          }}>
          <Grid
            container
            justify='flex-start'
            spacing={3}
            style={
              isSort
                ? {
                    paddingBottom: 100,
                    padding: 14,
                  }
                : {
                    paddingBottom: 100,
                  }
            }>
            {items && isSort ? (
              <DraggableList
                items={items}
                onDragEnd={handleDragEnd}
                renderItem={renderItem}
                isEditing={isSort}
                gridItemProps={{
                  display: "flex",
                  flexDirection: "column",
                }}
                position='position'
              />
            ) : (
              items?.map((item) => (
                <Grid item xs={12} sm={6} md={4} lg={4} key={item.item_id}>
                  <ItemCard
                    item={item}
                    enableEditDrawer={enableEditDrawer}
                    uploadImageCallBack={uploadImageCallBack}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Grid>
      </Grid>
      {isOpenComboModal && (
        <CombosModal
          cat_id={state.cat_id}
          itemId={itemId}
          open={isOpenComboModal}
          callback={createCallback}
          editCallback={editCallback}
          onClose={() => closeCreateItem()}
          deleteItemCallback={deleteItemCallback}
          copyItemCallback={copyItemCallback}
        />
      )}
      {isOpenPizzaModal && (
        <PizzasModal
          cat_id={state.cat_id}
          itemId={itemId}
          open={isOpenPizzaModal}
          callback={createCallback}
          editCallback={editCallback}
          onClose={() => {
            closeCreateItem();
            setItemId(null);
          }}
          deleteItemCallback={deleteItemCallback}
          copyItemCallback={copyItemCallback}
        />
      )}
      {snackbar && (
        <SnackBar
          open
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
      {openCreate && (
        <CreateItem
          open={openCreate}
          catName={state.name}
          id={state.cat_id}
          itemsList={items}
          isAlcohol={isAlcohol}
          callback={createCallback}
          onClose={() => {
            setItemId(null);
            toggleCreateDrawer(false);
          }}
          deleteItemCallback={deleteItemCallback}
        />
      )}
      {openEdit && (
        <CreateItem
          open={openEdit}
          catName={state.name}
          id={state.cat_id}
          itemsList={items}
          itemId={itemId}
          callback={editCallback}
          onClose={() => {
            toggleEditDrawer(false);
            setItemId(null);
          }}
          deleteItemCallback={deleteItemCallback}
          copyItemCallback={copyItemCallback}
        />
      )}
      {openAddon && (
        <AddonCreateItem
          open={openAddon}
          catNames={state.name}
          id={itemId}
          itemsList={items}
          callback={addonCallback}
          onClose={() => toggleAddonDrawer(false)}
        />
      )}
      {openSetPosition && (
        <SetPositionItem
          open={openSetPosition}
          itemsList={items}
          callback={positionCallback}
          onClose={() => togglePositionDrawer(false)}
        />
      )}
      {openCategoryEdit && (
        <EditCategory
          open={openCategoryEdit}
          category={state}
          callback={categoryCallback}
          deleteCallBack={deleteCallBack}
          onClose={() => toggleCategoryEditDrawer(false)}
        />
      )}
      <AlertDialog
        showDialog={showDialog}
        body={Context.langData.delete_all_items_body}
        heading={Context.langData.delete_all_items}
        IsError={false}
        handleClose={handleClose}
      />
    </Grid>
  );
};

const AddonCreateItem = ({
  open,
  callback,
  onClose,
  addonsData,
  servingsData,
}) => {
  const style = styles();
  const [expanded, setExpanded] = useState([]);
  const addons = useSelector((state) => state.menu.addons);
  const Context = useContext(MyContext);
  const [sendData, setSendData] = useState([]);
  const [value, setValue] = useState(0);
  const [servings, setServings] = useState([]);
  const [isError, setIsError] = useState(false);

  const save = () => {
    let errorCount = 0;
    if (sendData) {
      const arrayValues = [];
      sendData.map((item) => {
        const addonsArray = [];
        if (item.addons && item.addons.length > 0) {
          item.addons.map((addonItems) => {
            if (addonItems.addon_items.length > 0) {
              const obj = {};
              obj.addon_cat_id = addonItems.addon_cat_id;
              obj.addon_cat_name = addonItems.addon_cat_name;
              obj.minimum = addonItems.minimum;
              obj.maximum = addonItems.maximum;
              obj.addon_items = addonItems.addon_items;
              addonsArray.push(obj);
              if (addonItems.minError !== "" || addonItems.maxError !== "") {
                errorCount += 1;
              }
              if (addonItems.maximum >= addonItems.addon_items.length) {
                errorCount += 1;
              }
            }
          });
        }
        if (addonsArray.length > 0) {
          const serivingsobj = {};
          serivingsobj.serving = item.serving;
          serivingsobj.serving_id = item.serving_id;
          serivingsobj.addons = addonsArray;
          arrayValues.push(serivingsobj);
        }
      });
      if (errorCount === 0) {
        callback(arrayValues);
      } else {
        setIsError(true);
      }
    }
  };
  useEffect(() => {
    if (servingsData) {
      const array = [];
      setServings(servingsData);
      servingsData.map((item) => {
        const obj = {};
        obj.serving = item.name;
        obj.serving_id = item.serving_id;
        const addonsArray = [];
        addons.map((item) => {
          const obj = {};
          obj.addon_cat_id = item.addon_cat_id;
          obj.addon_cat_name = item.addon_cat_name;
          obj.minimum = 0;
          obj.maximum = 0;
          obj.minError = "";
          obj.maxError = "";
          obj.addon_items = [];
          addonsArray.push(obj);
        });
        obj.addons = addonsArray;
        array.push(obj);
      });
      if (addonsData.length > 0) {
        addonsData.map((item) => {
          const index = array.findIndex((a) => a.serving === item.serving);
          const selectedArray = array[index];
          const addonsArray = selectedArray.addons;
          if (item.addons.length > 0) {
            item.addons.map((addonItem) => {
              const addonIndex = addonsArray.findIndex(
                (a) => a.addon_cat_id === addonItem.addon_cat_id
              );
              const addon = addonsArray[addonIndex];
              const addonsItemsArray = [];
              if (addonItem.addon_items.length > 0) {
                addonItem.addon_items.map((addonItemId) => {
                  addonsItemsArray.push(addonItemId);
                });
              }
              addonsArray[addonIndex] = {
                ...addon,
                minimum: parseInt(addonItem.minimum, 10),
                maximum: parseInt(addonItem.maximum, 10),
                addon_items: addonsItemsArray,
              };
              selectedArray.addons = addonsArray;
              array[index] = selectedArray;
            });
          }
        });
      }

      setSendData(array);
    }
  }, [servingsData, addonsData]);
  const getAddonsList = (id) => {
    let addonsData = 0;
    if (addons) {
      const addonsListObj = addons.filter((e) => e.addon_cat_id === id);
      if (addonsListObj.length > 0) {
        addonsData = addonsListObj[0].addons.length;
      }
    }
    return addonsData;
  };
  const setMinValue = (minValue, id) => {
    setIsError(false);
    const newArray = [...sendData];
    const selectedArray = newArray[value];
    const { addons } = selectedArray;
    const addonIndex = addons.findIndex((a) => a.addon_cat_id === id);
    const addon = addons[addonIndex];
    const addonCount = getAddonsList(id);
    if (
      addon.maximum >= parseInt(minValue, 10) &&
      parseInt(minValue, 10) <= addonCount
    ) {
      addons[addonIndex] = {
        ...addon,
        minimum: parseInt(minValue, 10),
        minError: "",
        maxError: "",
      };
    } else {
      addons[addonIndex] = {
        ...addon,
        minimum: parseInt(minValue, 10),
        minError: "inValid",
        maxError: "",
      };
    }
    selectedArray.addons = addons;
    newArray[value] = selectedArray;
    setSendData(newArray);
  };
  const setMaxValue = (maxValue, id) => {
    setIsError(false);
    const newArray = [...sendData];
    const selectedArray = newArray[value];
    const { addons } = selectedArray;
    const addonIndex = addons.findIndex((a) => a.addon_cat_id === id);
    const addon = addons[addonIndex];
    const addonCount = getAddonsList(id);
    if (
      addon.minimum <= parseInt(maxValue, 10) &&
      parseInt(maxValue, 10) <= addonCount
    ) {
      addons[addonIndex] = {
        ...addon,
        maximum: parseInt(maxValue, 10),
        maxError: "",
        minError: "",
      };
    } else {
      addons[addonIndex] = {
        ...addon,
        maximum: parseInt(maxValue, 10),
        maxError: "inValid",
        minError: "",
      };
    }
    selectedArray.addons = addons;
    newArray[value] = selectedArray;
    setSendData(newArray);
  };
  const getMinValue = (id) => {
    let minValue = 0;
    if (sendData.length > 0) {
      const { addons } = sendData[value];
      if (addons.length > 0) {
        const addon = addons.filter((a) => a.addon_cat_id === id);
        minValue = addon[0].minimum || 0;
      }
    }
    return minValue;
  };
  const getMaxValue = (id) => {
    let maxValue = 0;
    if (sendData.length > 0) {
      const { addons } = sendData[value];
      if (addons.length > 0) {
        const addon = addons.filter((a) => a.addon_cat_id === id);
        maxValue = addon[0].maximum || 0;
      }
    }
    return maxValue;
  };
  const getCheckedValue = (id, addonId) => {
    let isChecked = false;
    if (sendData && sendData[value]) {
      const array = sendData[value].addons.filter((e) => e.addon_cat_id === id);
      if (array.length > 0) {
        if (array[0].addon_items.length > 0) {
          const addonArray = array[0].addon_items.filter((e) => e === addonId);
          if (addonArray.length > 0) {
            isChecked = true;
          }
        }
      }
    }
    return isChecked;
  };
  const setCheckValue = (id, addon_item_id) => {
    setIsError(false);
    const newArray = [...sendData];
    const selectedArray = newArray[value];
    const { addons } = selectedArray;
    const addonIndex = addons.findIndex((a) => a.addon_cat_id === id);
    let ArrayValue = [];
    const addon = addons[addonIndex];
    if (addon.addon_items.length > 0) {
      ArrayValue = addon.addon_items;
      const index = ArrayValue.indexOf(addon_item_id);
      if (index !== -1) {
        ArrayValue.splice(index, 1);
      } else {
        ArrayValue.push(addon_item_id);
      }
    } else {
      ArrayValue.push(addon_item_id);
    }
    addons[addonIndex] = { ...addon, addon_items: ArrayValue };
    selectedArray.addons = addons;
    newArray[value] = selectedArray;
    setSendData(newArray);
  };
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange = (catName) => {
    const array = [...expanded];
    if (array.length > 0) {
      const isIndex = array.findIndex((e) => e === catName);
      if (isIndex === -1) {
        array.push(catName);
      } else {
        array.splice(isIndex, 1);
      }
    } else {
      array.push(catName);
    }
    setExpanded(array);
  };
  const IsExpandTab = (value) => {
    let isExpandReturn = false;
    if (expanded) {
      const isIndex = expanded.findIndex((e) => e === value);
      if (isIndex !== -1) {
        isExpandReturn = true;
      }
    }
    return isExpandReturn;
  };
  const getErrorValue = (catId, isMin) => {
    let errorVal = "";
    if (sendData.length > 0) {
      const { addons } = sendData[value];
      if (addons.length > 0) {
        const addon = addons.filter((a) => a.addon_cat_id === catId);
        if (isMin) {
          errorVal = addon[0].minError;
        } else {
          errorVal = addon[0].maxError;
        }
      }
    }

    return errorVal;
  };
  return (
    <RightDrawer open={open} padding={1}>
      <Grid
        style={{ display: "flex", height: "100%", flexDirection: "column" }}>
        <Grid
          style={{
            display: "flex",
            height: "85%",
            flexDirection: "column",
            overflowX: "auto",
          }}>
          <Grid
            data-qaid='assignkitchen_close_grid'
            style={{ display: "flex", justifyContent: "flex-end" }}>
            <CircleClose
              data-qaid='assignkitchen_closeicon'
              size={20}
              onClick={onClose}
              style={{ marginRight: 40, marginTop: 20 }}
            />
          </Grid>
          <Grid
            data-qaid='assignkitchen_settings_grid'
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginLeft: 56,
              marginBottom: 10,
            }}>
            <BackIcon
              data-qaid='categories_editBack_icon'
              onClick={() => onClose()}
              style={{ cursor: "pointer" }}
            />
          </Grid>
          <H8
            data-qaid='assignlitchen_title'
            style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }}
            label={Context.langData.create_addon_item}
          />
          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              overflowY: "auto",
            }}>
            <Tabs
              style={{ marginLeft: 56, borderBottom: "1px solid #EEEEEE" }}
              variant='scrollable'
              value={value}
              indicatorColor='primary'
              textColor='primary'
              onChange={handleTabChange}>
              {servings && servings.length > 0
                ? servings.map((item, index) => (
                    <Tab
                      key={index}
                      label={<AvenirBlackH4 label={item.name} />}
                      value={index}
                    />
                  ))
                : null}
            </Tabs>
            <Grid
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 56,
                marginRight: 56,
                position: "sticky",
              }}>
              {addons &&
                addons.map((item, index3) => (
                  <Accordion
                    key={index3}
                    expanded={IsExpandTab(item.addon_cat_name)}
                    onChange={() => {
                      handleChange(item.addon_cat_name);
                    }}
                    style={{
                      maxWidth: 500,
                      marginTop: 10,
                      marginBottom: 10,
                    }}>
                    <AccordionSummary
                      aria-controls='panel1d-content'
                      expandIcon={<DownVector />}
                      id='panel1d-header'
                      style={{ borderBottom: "1px solid #EEEEEE" }}>
                      <Grid
                        container
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}>
                        <Grid
                          style={{
                            display: "flex",
                            width: "40%",
                            alignItems: "center",
                          }}>
                          <AvenirBlackH4 label={item.addon_cat_name} />
                        </Grid>
                        <Grid
                          style={{
                            display: "flex",
                            width: "10%",
                            flexDirection: "row",
                            alignItems: "center",
                          }}>
                          <AvenirBlackH4 label={Context.langData.min} />
                        </Grid>
                        <Grid
                          style={{
                            display: "flex",
                            width: "10%",
                            flexDirection: "row",
                            marginRight: "8%",
                          }}>
                          <InputText
                            value={getMinValue(item.addon_cat_id)}
                            type='text'
                            isPlaceHolder={false}
                            placeholder={0}
                            error={getErrorValue(item.addon_cat_id, true)}
                            onChange={(val) => {
                              setMinValue(val.target.value, item.addon_cat_id);
                            }}
                            id={`min${item.addon_cat_id}`}
                            style={{ borderColor: "#ff0000" }}
                          />
                        </Grid>
                        <Grid
                          style={{
                            display: "flex",
                            width: "10%",
                            flexDirection: "row",
                            alignItems: "center",
                          }}>
                          <AvenirBlackH4 label={Context.langData.max} />
                        </Grid>
                        <Grid
                          style={{
                            display: "flex",
                            width: "10%",
                            flexDirection: "row",
                          }}>
                          <InputText
                            value={getMaxValue(item.addon_cat_id)}
                            onChange={(val) => {
                              setMaxValue(val.target.value, item.addon_cat_id);
                            }}
                            type='text'
                            isPlaceHolder={false}
                            placeholder={0}
                            error={getErrorValue(item.addon_cat_id, false)}
                            id={`max${item.addon_cat_id}`}
                          />
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        style={{
                          width: 700,
                          display: "flex",
                          flexFlow: "wrap",
                        }}>
                        {item.addons
                          ? item.addons.map((addonItem, index2) => (
                              <Grid
                                key={index2}
                                style={{
                                  width: "28%",
                                  flexDirection: "row",
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: 20,
                                }}>
                                <Checkbox
                                  id={addonItem.addon_item_name}
                                  checked={getCheckedValue(
                                    item.addon_cat_id,
                                    addonItem.addon_item_id
                                  )}
                                  onChange={() =>
                                    setCheckValue(
                                      item.addon_cat_id,
                                      addonItem.addon_item_id
                                    )
                                  }
                                  style={{ color: "black" }}
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                />
                                <H4
                                  label={addonItem.addon_item_name}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                />
                              </Grid>
                            ))
                          : null}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{
            display: "flex",
            height: "15%",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}>
          <Grid
            data-qaid='assignkitchen_action_grid'
            container
            style={{
              backgroundColor: "#FAFAFA",
              border: "1px solid #C3C3C3",
              marginTop: 20,
            }}>
            <Grid style={{ display: "flex", marginLeft: 56 }}>
              {isError ? (
                <H4
                  label={Context.langData.addons_error}
                  style={{ color: "#FF0000" }}
                />
              ) : null}
            </Grid>
            <Grid
              data-qaid='assignkitchen_cancel_btn'
              item
              xs={12}
              lg={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 32,
                marginBottom: 32,
              }}>
              <Button
                className={style.buttonClass}
                onClick={onClose}
                variant='contained'
                style={{ backgroundColor: "#00B6C9", marginRight: 16 }}>
                <AvenirBlackH4
                  data-qaid='createkitchen_cancel_text'
                  label={Context.langData.cancel}
                />
              </Button>
              <Button
                className={style.buttonClass}
                data-qaid='assignkitchen_save_btn'
                variant='contained'
                style={{ backgroundColor: "#FFB600", marginRight: 32 }}
                onClick={() => save()}>
                <AvenirBlackH4
                  data-qaid='assignkitchen_save_text'
                  label={Context.langData.save}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RightDrawer>
  );
};
const SetPositionItem = ({ open, callback, onClose, itemsList }) => {
  const dispatch = useDispatch();
  const CloseCallback = (value, data) => {
    if (value) {
      if (data.length > 0) {
        const array = [];
        data.map((item, index) => {
          const obj = [];
          obj.push(item.item_id);
          obj.push(index);
          array.push(obj);
        });
        dispatch(createItemPosition(array, callback));
      }
    } else {
      onClose();
    }
  };
  return (
    <RightDrawer open={open} style={{ width: 500 }}>
      <Position data={itemsList} callBack={CloseCallback} />
    </RightDrawer>
  );
};

export default Items;
