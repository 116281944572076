import React, { useContext } from "react";
import { Grid, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import moment from "moment";
import { createCatePosition } from "../../../redux/actions";
import MyContext from "../../../Provider/MyContext";
import { H4, H2, AvenirBlackH4, H6, AvenirBlackH3 } from "../../../utils/text";
import MenuIcon from "../../../components/SVG/MenuIcon";
import LineIcon from "../../../components/SVG/Line";
import styles from "../../../styles/index";

const Positions = () => {
  const style = styles();
  const categories = useSelector((state) => state.menu.categories);
  const Context = useContext(MyContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cats = _.sortBy(categories, [
    function (o) {
      return o.order;
    },
  ]);
  const handleClose = () => {
    navigate("/home/menu/categories");
  };
  const CloseCallback = (value, data) => {
    if (value) {
      if (data.length > 0) {
        const array = [];
        data.map((item, index) => {
          const obj = [];
          obj.push(item.cat_id);
          obj.push(index);
          array.push(obj);
        });
        dispatch(
          createCatePosition(array, () => {
            handleClose();
          })
        );
      }
    } else {
      handleClose();
    }
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const grid = 8;
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    display: "flex",
    flexDirection: "row",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    border: `1px solid #C3C3C3`,
    marginLeft: 32,
    marginRight: 32,
    marginBottom: 16,
    borderRadius: 5,
    background: isDragging ? "#EEEEEE" : "#FFFFFF",
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#EEEEEE" : "#FFFFFF",
    padding: grid,
    width: 250,
  });
  const [data, setData] = React.useState(cats);
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(data, result.source.index, result.destination.index);
    setData(items);
  };
  const getDateFormat = (timestamp) => {
    const val = moment.unix(timestamp).format("MMMM DD, YYYY");
    return val;
  };
  return (
    <Grid
      data-qaid='categories_main_grid'
      container
      justify='flex-start'
      spacing={3}
      style={{ backgroundColor: "#FFFFFF" }}>
      <Grid
        data-qaid='categories_grid'
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          marginRight: 20,
          marginBottom: 10,
          marginTop: 32,
          marginLeft: 32,
        }}>
        <Grid
          data-qaid='categories_grid'
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}>
          <H6 label={Context.langData.menu} />
        </Grid>
        <Grid
          data-qaid='categories_grid'
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
          }}>
          <Button
            className={style.buttonClass}
            data-qaid='categories_creategrid'
            style={{
              backgroundColor: "#FFF",
              color: "white",
              marginRight: 10,
              border: "2px solid #051D33",
              height: 40,
              width: 103,
            }}
            variant='contained'
            id='cancel'
            onClick={() => navigate("/home/menu/categories")}>
            <AvenirBlackH4
              data-qaid='categories_createlabel'
              label={Context.langData.cancel}
            />
          </Button>
          <Button
            className={style.buttonClass}
            data-qaid='categories_creategrid'
            style={{
              backgroundColor: "#FFB600",
              color: "white",
              marginRight: 10,
              height: 40,
              width: 90,
            }}
            variant='contained'
            id='cancel'
            onClick={() => CloseCallback(true, data)}>
            <AvenirBlackH4
              data-qaid='categories_createlabel'
              label={Context.langData.save}
            />
          </Button>
        </Grid>
      </Grid>
      <Grid style={{ display: "flex", marginLeft: 40, marginBottom: 32 }}>
        <H2 label={Context.langData.categories} />
      </Grid>
      <Grid data-qaid='categories_body_grid' item xs={12}>
        <Grid
          data-qaid='categories_child_grid'
          container
          justify='flex-start'
          spacing={3}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='droppable'>
              {(provided, snapshot) => (
                <Grid
                  data-qaid='editCategory_cancel_btn'
                  item
                  xs={12}
                  lg={12}
                  sm={12}
                  md={12}
                  style={{
                    justifyContent: "flex-end",
                    marginTop: 32,
                    marginBottom: 32,
                    ...getListStyle(snapshot.isDraggingOver),
                  }}
                  {...provided.droppableProps}
                  ref={provided.innerRef}>
                  {data.map((item, index) => (
                    <Draggable
                      key={item.name}
                      draggableId={item.name}
                      index={index}>
                      {(provided, snapshot) => (
                        <Grid
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}>
                          <Grid
                            item
                            xs={12}
                            lg={12}
                            sm={12}
                            md={12}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}>
                            <MenuIcon style={{ marginRight: 20 }} />
                            <AvenirBlackH3
                              label={item.name}
                              style={{ marginRight: 16 }}
                            />
                            <LineIcon style={{ marginRight: 16 }} />
                            <H4
                              label={`${
                                Context.langData.lastModified
                              } ${getDateFormat(item.last_updated_on)}`}
                              style={{ color: "#54575A" }}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Positions;
