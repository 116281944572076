import React, { useState } from "react";
import {
  Select,
  Checkbox,
  MenuItem,
  FormControl,
  makeStyles,
  withStyles,
  Chip,
  Tooltip,
} from "@material-ui/core";
import _ from "lodash";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import { H4, DotText } from "../utils/text";
import colors from "../styles/colors";
import { DownVector } from "./SVG";

const MultiSelectChip = ({
  list,
  selectedValue = [],
  handleChange,
  value,
  name,
  placeholder = "",
  border = false,
  minWidth,
  height,
  width,
  tooltipMessage = "",
  disableMultiChip = false,
  ...props
}) => {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(0),
    },
    root: {
      "& .MuiFilledInput-root": {
        backgroundColor: "#FFF",
        "&.Mui-disabled": {
          backgroundColor: "#FAFAFA",
          borderRadius: 5,
          border: "1px solid #C3C3C3",
        },
      },
    },
    selectRoot: {
      height: "30px",
      alignContent: "center",
      position: "relative",
      borderRadius: 5,
      padding: 3,
      backgroundClip: "padding-box",
      border: "1px solid #707272",
      overflowY: "auto",
      "&.disabled": {
        border: "1px solid #c3c3c3",
      },
      "&.focused": {
        border: "1px solid #707272",
        borderRadius: 5,
        backgroundClip: "padding-box",
        boxShadow: "0 0 0 2px #FFFFFF,0 0 0 4px #004c60",
      },
      "&:focus": {
        outline: "none",
        border: "1px solid #707272",
        borderRadius: 5,
        backgroundClip: "padding-box",
      },
    },

    chip: {
      margin: 2,
    },
    chipsContainer: {
      display: "flex",
      flexWrap: "wrap",
      maxHeight: 100,
    },

    customToolTip: {
      backgroundColor: "#051D33",
      color: "#FFF",
      fontSize: "14px",
      fontWeight: 300,
      fontFamily: "AvenirLT",
    },
    customArrow: {
      color: "#051D33",
    },
    customCheckbox: {
      "&.MuiCheckbox-colorSecondary.Mui-checked": {
        color: "#004C60",
      },
    },
    hoverMenuItem: {
      "&:hover": {
        backgroundColor: "#F3FAFD",
      },
    },
    menuPropsStyle: (props) => ({
      minWidth: props.width,
      position: "absolute",
      maxHeight: 200,
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#004C60",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#FAFAFA",
      },
    }),
  }));
  const classes = useStyles({ border });
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const menuProps = {
    PaperProps: {
      style: {
        marginTop: 8,
        backgroundColor: "#FFFFFF",
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
    classes: {
      paper: classes.menuPropsStyle,
    },
  };
  const [open, setOpen] = useState(false);

  const changeValue = (e) => {
    handleChange(e.target.value);
  };

  const handleDelete = (chipToDelete) => (event) => {
    event.stopPropagation();
    event.preventDefault();
    handleChange(selectedValue.filter((value) => value !== chipToDelete));
  };

  const selectedRender = (selected) => {
    if (selected.length === 0) {
      return <H4 label={placeholder} style={{ color: colors.greyText }} />;
    }
    return (
      <div className={classes.chipsContainer}>
        {selected.map((s) => {
          const item = _.find(list, (li) => li[value] === s);
          if (item) {
            return (
              <Chip
                key={s}
                label={item[name]}
                onDelete={handleDelete(s)}
                className={classes.chip}
                deleteIcon={<CloseIcon style={{ color: "#007191" }} />}
                onMouseDown={(event) => event.stopPropagation()}
                style={{
                  backgroundColor: "#FFF",
                  border: "1px solid #C3C3C3",
                  borderRadius: 5,
                  paddingRight: 5,
                }}
              />
            );
          }
          return null;
        })}
      </div>
    );
  };

  const CustomExpandVector = withStyles()(({ open, disabled, ...rest }) => (
    <DownVector
      {...rest}
      style={{
        marginTop: 8,
        marginRight: 5,
      }}
      color={disabled ? "#c3c3c3" : "#004c60"}
    />
  ));

  return (
    <FormControl
      data-qaid='multiselect_form_control'
      variant={border ? "outlined" : "filled"}
      style={{
        minWidth,
        width,
        maxWidth: minWidth,
        background: "#FFF",
        display: "flex",
        marginTop: 3,
      }}>
      <Tooltip
        title={disableMultiChip && tooltipMessage ? tooltipMessage : ""}
        disableHoverListener={!disableMultiChip}
        arrow
        classes={{ tooltip: classes.customToolTip, arrow: classes.customArrow }}
        placement='bottom-end'>
        <Select
          data-qaid='multiselect_select'
          labelId='demo-multiple-checkbox-label'
          id='demo-multiple-checkbox'
          multiple
          displayEmpty
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent={(props) => (
            <CustomExpandVector
              {...props}
              open={open}
              disabled={disableMultiChip}
            />
          )}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          classes={{
            root: clsx(classes.selectRoot, {
              disableMultiChip,
              focused: open && !disableMultiChip,
            }),
          }}
          style={{
            backgroundColor: "#FFFFFF",
            fontSize: 14,
            fontFamily: "AvenirLT",
            width,
            borderRadius: 4,
            alignItems: "center",
          }}
          disableUnderline
          value={selectedValue}
          onChange={changeValue}
          renderValue={selectedRender || placeholder}
          MenuProps={menuProps}
          disabled={disableMultiChip}
          {...props}>
          {list.map((item, index) => (
            <MenuItem
              data-qaid='multiselect_menuitem'
              key={index}
              value={item[value]}
              classes={{ root: classes.hoverMenuItem }}
              style={{
                height: 56,
                borderBottom: "1px solid #EEEEEE",
                width,
              }}>
              <Checkbox
                data-qaid='multiselect_checkbox'
                classes={{ root: classes.customCheckbox }}
                checked={selectedValue.indexOf(item[value]) > -1}
              />
              <DotText
                data-qaid='multiselect_label_text'
                style={{ color: "#051D33" }}
                label={item[name]}
              />
            </MenuItem>
          ))}
        </Select>
      </Tooltip>
    </FormControl>
  );
};

export default React.memo(MultiSelectChip);
