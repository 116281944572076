import { Button, Grid, makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoData from "../../../components/NoData";
import SnackBar from "../../../components/SnackBar";
import EditIcon from "../../../components/SVG/Edit";
import CirclePluse from "../../../components/SVG/PluseIcon";
import { RightArrow } from "../../../components/SVG";
import MyContext from "../../../Provider/MyContext";
import {
  deleteCategories,
  fetchAddonCategories,
  fetchAllAddons,
  fetchAllCategories,
  fetchOrderTypes,
  getPizzaConfig,
  fetchDietaryAttributes,
} from "../../../redux/actions";
import styles from "../../../styles/index";
import { AvenirBlackH3, AvenirBlackH4, H2, H4 } from "../../../utils/text";
import AlertDialog from "../../../components/Dialog";
import CreateCategory from "./createCategory";
import EditCategory from "./editCategory";
import SetPositionItem from "./setPositionItem";

const useStyles = makeStyles({
  editAndViewTextStyle: {
    paddingLeft: 5,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    color: "#004C60",
  },
});

const Categories = () => {
  const classNames = useStyles();
  const categories = useSelector((state) => state.menu.categories);
  const style = styles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedCat, setSelectedCat] = useState({});
  const [openCreate, toggleCreateDrawer] = useState(false);
  const [openCategoryEdit, toggleCategoryEditDrawer] = useState(false);
  const cats = _.sortBy(categories, [
    function sort(o) {
      return o.order;
    },
  ]);
  const catNames = categories.map((cat) => cat.name.toLowerCase());
  const [snackbar, setSnackbar] = useState(null);
  const [openSetPostion, togglePostionDrawer] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [id] = useState("");
  const Context = useContext(MyContext);
  const [heading] = useState(Context.langData.delete_item_cat);
  const [bodyMessage] = useState(Context.langData.delete_item_body);
  useEffect(() => {
    dispatch(fetchOrderTypes());
    dispatch(fetchAllCategories());
    dispatch(fetchAllAddons());
    dispatch(fetchAddonCategories());
    dispatch(getPizzaConfig());
    dispatch(fetchDietaryAttributes());
  }, []);
  const createCallback = (isSuccess) => {
    if (isSuccess) {
      toggleCreateDrawer(false);
      togglePostionDrawer(false);
      setSnackbar({
        message: Context.langData.cat_create_succ,
        severity: "success",
      });
      dispatch(fetchAllCategories());
    } else {
      setSnackbar({
        message: Context.langData.category_create_failed,
        severity: "error",
      });
    }
  };
  const postionCallback = () => {
    toggleCreateDrawer(false);
    toggleCategoryEditDrawer(false);
    togglePostionDrawer(false);
    dispatch(fetchAllCategories());
  };

  const deleteCallback = () => {
    toggleCreateDrawer(false);
    toggleCategoryEditDrawer(false);
    setSnackbar({ message: Context.langData.delete_succ, severity: "success" });
    dispatch(fetchAllCategories());
  };

  const editCallback = (isSuccess) => {
    if (isSuccess) {
      toggleCreateDrawer(false);
      toggleCategoryEditDrawer(false);
      setSnackbar({
        message: Context.langData.cat_edit_succ,
        severity: "success",
      });
      dispatch(fetchAllCategories());
    } else {
      setSnackbar({
        message: Context.langData.category_edit_failed,
        severity: "error",
      });
    }
  };

  const handleClose = (val) => {
    if (val) {
      dispatch(deleteCategories([id], deleteCallback));
    }
    setShowDialog(false);
  };
  const getDateFormat = (timestamp) => {
    const val = moment.unix(timestamp).format("MMMM DD, YYYY");
    return val;
  };
  return (
    <Grid
      data-qaid='categories_main_grid'
      container
      justify='flex-start'
      spacing={3}
      style={{ backgroundColor: "#FFFFFF", borderTop: "1px solid #EEEEEE" }}>
      {cats.length > 0 ? (
        <>
          <Grid
            data-qaid='categories_grid'
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginRight: 20,
              marginBottom: 10,
              marginTop: 32,
              marginLeft: 32,
            }}>
            <Grid
              data-qaid='categories_grid'
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}>
              <H2 label={Context.langData.categories} />
            </Grid>
            <Grid
              data-qaid='categories_grid'
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}>
              <Button
                className={style.buttonClass}
                data-qaid='categories_creategrid'
                style={{
                  backgroundColor: "#00B6C9",
                  color: "white",
                  marginRight: 10,
                  height: 40,
                }}
                variant='contained'
                id='cancel'
                onClick={() => navigate("/home/menu/categories/positions")}>
                <AvenirBlackH4
                  data-qaid='categories_createlabel'
                  label={Context.langData.sort}
                />
              </Button>
              <Button
                className={style.buttonClass}
                data-qaid='categories_creategrid'
                style={{
                  backgroundColor: "#FFB600",
                  color: "white",
                  marginRight: 10,
                  height: 40,
                  width: 157,
                }}
                variant='contained'
                id='cancel'
                onClick={() => toggleCreateDrawer(true)}>
                <CirclePluse data-qaid='categories_createpluse' />
                <AvenirBlackH4
                  data-qaid='categories_createlabel'
                  label={Context.langData.create_new}
                  style={{ paddingLeft: 5 }}
                />
              </Button>
            </Grid>
          </Grid>
          <Grid
            data-qaid='categories_body_grid'
            item
            xs={12}
            style={{ marginLeft: 32, marginRight: 32, marginBottom: 30 }}>
            <Grid
              data-qaid='categories_child_grid'
              container
              justifyContent='flex-start'
              spacing={3}>
              {cats.map((cat) => (
                <Grid
                  data-qaid='categories_content_grid'
                  key={cat.cat_id}
                  style={{
                    border: `1px solid #C3C3C3`,
                    marginTop: 10,
                    display: "flex",
                    alignItems: "center",
                    borderRadius: 5,
                    flexDirection: "row",
                    width: "100%",
                    height: 78,
                  }}>
                  <Grid
                    data-qaid='categories_right_grid'
                    item
                    xs={12}
                    lg={1}
                    sm={12}
                    md={1}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: cat.bg_colour,
                      borderRight: `1px solid #C3C3C3`,
                      borderRadius: "5px 0px 0px 5px",
                      margin: "0px 0px 0px 0px",
                      alignCenter: "center",
                      height: "100%",
                      padding: 5,
                      textAlign: "center",
                    }}>
                    <AvenirBlackH3
                      data-qaid='categories_cat_name_label'
                      label={cat.name}
                      style={{
                        color: cat.font_colour,
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        alignCenter: "center",
                      }}
                    />
                  </Grid>
                  <Grid
                    data-qaid='categories_child_grid'
                    item
                    xs={12}
                    lg={9}
                    sm={12}
                    md={9}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginLeft: 16,
                    }}>
                    <H4
                      data-qaid='categories_lostmodified'
                      label={`${
                        Context.langData.lastModified
                      } ${getDateFormat(cat.last_updated_on)}`}
                      style={{ color: "#54575A" }}
                    />
                  </Grid>
                  <Grid
                    data-qaid='categories_right_grid'
                    item
                    xs={12}
                    lg={2}
                    sm={12}
                    md={2}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      marginRight: 32,
                    }}>
                    <Button
                      startIcon={<EditIcon color='#007191' />}
                      data-qaid='categories_creategrid'
                      style={{
                        color: "white",
                        marginRight: 10,
                        backgroundColor: "white",
                      }}
                      id='editCategory'
                      onClick={() => {
                        toggleCategoryEditDrawer(true);
                        setSelectedCat(cat);
                      }}>
                      <AvenirBlackH4
                        data-qaid='categories_createlabel'
                        label={Context.langData.edit}
                        className={classNames.editAndViewTextStyle}
                      />
                    </Button>
                    <Button
                      id='viewItems'
                      endIcon={<RightArrow color='#007191' />}
                      style={{
                        color: "white",
                        backgroundColor: "white",
                        marginRight: 10,
                      }}
                      onClick={() =>
                        navigate("/home/menu/categories/items-list", {
                          state: cat,
                        })
                      }>
                      <AvenirBlackH4
                        data-qaid='categories_createlabel'
                        label={`${Context.langData.view_items}`}
                        className={classNames.editAndViewTextStyle}
                      />
                    </Button>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </>
      ) : (
        <NoData page='category' callback={() => toggleCreateDrawer(true)} />
      )}

      {snackbar && (
        <SnackBar
          data-qaid='categories_snackbar'
          open
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}

      {openCreate && (
        <CreateCategory
          data-qaid='categories_createcategory'
          open={openCreate}
          catNames={catNames}
          callback={createCallback}
          onClose={() => toggleCreateDrawer(false)}
        />
      )}
      {openSetPostion && (
        <SetPositionItem
          data-qaid='categories_setpostion'
          open={openSetPostion}
          itemsList={cats}
          callback={postionCallback}
          onClose={() => togglePostionDrawer(false)}
        />
      )}
      {openCategoryEdit && (
        <EditCategory
          open={openCategoryEdit}
          category={selectedCat}
          callback={editCallback}
          deleteCallBack={deleteCallback}
          onClose={() => toggleCategoryEditDrawer(false)}
        />
      )}
      <AlertDialog
        data-qaid='categories_alertDialog'
        showDialog={showDialog}
        body={bodyMessage}
        heading={heading}
        IsError={false}
        handleClose={handleClose}
      />
    </Grid>
  );
};

export default Categories;
