import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useLDClient } from "launchdarkly-react-client-sdk";
import clsx from "clsx";
import {
  Box,
  ListItemIcon,
  Drawer,
  ListItemText,
  Hidden,
  List,
  ListItem,
  makeStyles,
} from "@material-ui/core";

import { FeatureKeys } from "../../../enums";
import {
  Dashboard as DashboardIcon,
  Menu as MenuBookIcon,
  Report as AssessmentIcon,
  User as AccountCircleOutlinedIcon,
  Settings as SettingsIcon,
  Transaction as SwapHorizontalCircleIcon,
  InventoryIcon,
  BetaIcon,
  PettyCashIcon,
} from "../../../components/SVG";
import MyContext from "../../../Provider/MyContext";

import MiniDrawer from "./MiniDrawer";

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 200,
  },
  desktopDrawer: {
    width: 200,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  toolbar: {
    backgroundColor: "#fafafa",
  },
  title: {
    fontFamily: "AvenirLT",
    fontSize: 14,
    fontWeight: 400,
  },
  subtitle: {
    fontFamily: "AvenirLT",
    fontSize: 12,
    fontWeight: 400,
    color: "#004C60",
  },
  btn: {
    paddingRight: 0,
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    fontFamily: "AvenirLT",
    height: 50,
    "&:hover": {
      backgroundColor: "#C8E6F1",
    },
    "&.active": {
      backgroundColor: "#C8E6F1",
      textDecoration: "underline",
    },
  },
  nested: {
    paddingLeft: theme.spacing(6),
    paddingTop: 0,
    paddingBottom: 0,
  },
  icon: {
    minWidth: 35,
    width: 20,
    height: "100%",
    color: "#004C60",
    marginRight: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&.active": {
      borderBottom: "2px solid #051D33",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const Context = useContext(MyContext);
  const selectedRestaurant = useSelector(
    (state) => state.loginReducer.selectedRestaurant
  );
  const Permissions = useSelector((state) => state.profileReducers.permissions);
  const isDrawerOpen = useSelector((state) => state.dashboard.isDrawerOpen);
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);

  const items = [
    {
      id: 1,
      href: "/home/dashboard",
      icon: DashboardIcon,
      title: Context.langData.dashboard,
    },
    {
      id: 2,
      icon: SwapHorizontalCircleIcon,
      title: Context.langData.transations,
      href: "/home/transactions",
    },
    {
      id: 3,
      icon: AssessmentIcon,
      title: Context.langData.reports,
      href: "/home/reports",
    },
    {
      id: 4,
      icon: MenuBookIcon,
      title: Context.langData.menu,
      href: "/home/menu/categories",
    },
    {
      id: 5,
      icon: AccountCircleOutlinedIcon,
      title: Context.langData.profiles,
      href: "/home/profile",
    },
    {
      id: 6,
      icon: InventoryIcon,
      title: Context.langData.inventory,
      href: "/home/inventory",
      iconBeta: BetaIcon,
    },
    {
      id: 7,
      icon: PettyCashIcon,
      title: Context.langData.pettycash,
      href: "/home/pettyCash",
    },
    {
      id: 9,
      icon: SettingsIcon,
      title: Context.langData.settings,
      href: "/home/settings",
    },
  ];

  // Check if inventory is available for the restaurant which is controlled by feature flag
  const featureFlagClient = useLDClient();
  if (featureFlagClient && selectedRestaurant && selectedRestaurant.rest_id) {
    const context = {
      kind: "restaurant",
      key: "restaurant",
      restId: selectedRestaurant.rest_id,
    };
    featureFlagClient.identify(context, null, () => {});

    const inventoryFlagValue = featureFlagClient.variation(
      FeatureKeys.Inventory,
      true
    );
    const pettyCashFlagValue = featureFlagClient.variation(
      FeatureKeys.PettyCash,
      true
    );

    if (inventoryFlagValue === false) {
      // Remove inventory from items if it's not available
      const inventory = (element) => element.id === 6;
      const index = items.findIndex(inventory);
      items.splice(index, 1);
    }
    if (pettyCashFlagValue === false) {
      // Remove pettyCash from items if it's not available
      const pettyCash = (element) => element.id === 7;
      const index = items.findIndex(pettyCash);
      items.splice(index, 1);
    }
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (
      Permissions &&
      Permissions.permissions &&
      Permissions.permissions.length > 0
    ) {
      let permissionsIds = [];
      if (selectedRestaurant && selectedRestaurant.status === 1) {
        permissionsIds =
          (selectedRestaurant && selectedRestaurant.permission) ||
          selectedRestaurant.permission;
        const portalPermissions = Permissions.permissions.filter(
          (e) => e.application_id === 1
        );
        const userPermissions = [];
        if (permissionsIds) {
          Object.keys(permissionsIds).forEach((key) => {
            if (portalPermissions.length > 0) {
              const permissionItem = portalPermissions.filter(
                (e) => e.permission_id === parseInt(key, 10)
              );
              if (permissionItem.length > 0) {
                const permissionObj = {
                  ...permissionItem[0],
                  status: permissionsIds[key] === 1,
                };
                userPermissions.push(permissionObj);
              }
            }
          });
        }
        dispatch({ type: "SET_USER_PERMISSIONS", payload: userPermissions });
      } else {
        navigate("/noAccess");
      }
    }
  }, [Permissions]);
  const toggleDrawer = (flag) => {
    dispatch({ type: "TOGGLE_NAVBAR", payload: flag });
  };
  const setOpenItem = (title) => {
    if (open === title) {
      setOpen(null);
    } else {
      setOpen(title);
    }
  };
  const content = (
    <Box
      height='100%'
      display='flex'
      flexDirection='column'
      className={classes.toolbar}>
      <Box>
        <List
          style={{ color: "white", paddingTop: 40, height: "620px" }}
          component='nav'
          aria-labelledby='nested-list-subheader'>
          {items.map((item) => (
            <NavItem
              key={item.title}
              item={item}
              open={open}
              isDrawerOpen={isDrawerOpen}
              handleClick={() => setOpenItem(item.title)}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden mdUp>
        <Drawer
          anchor='left'
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant='temporary'>
          {content}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <MiniDrawer
          classes1={classes}
          toggleDrawer={() => {
            toggleDrawer(!isDrawerOpen);
            setOpen(null);
          }}
          open={isDrawerOpen}>
          {content}
        </MiniDrawer>
      </Hidden>
    </>
  );
};

const NavItem = ({ item, isDrawerOpen }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split("/");
  const hrefText = `/${path[2]}/${path[3]}`;
  const getClassNames = () => {
    if (item.href === hrefText) {
      if (isDrawerOpen) {
        return clsx(classes.btn, "active");
      }
      return clsx(classes.btn, "active", "open");
    }
    return clsx(classes.btn);
  };
  const NavIcon = item.icon;
  const NavIconBeta = item.iconBeta;
  return (
    <ListItem
      button
      className={getClassNames()}
      style={{ color: "#051D33", paddingLeft: 8, paddingRight: 8 }}
      onClick={() => navigate(item.href)}>
      <ListItemIcon
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          minWidth: "40px",
        }}>
        <span
          className={
            item.href === hrefText && !isDrawerOpen
              ? clsx(classes.icon, "active")
              : clsx(classes.icon)
          }>
          <NavIcon
            color={item.href === hrefText ? "#051d33" : "#004C60"}
            width={20}
            height={20}
          />
        </span>
      </ListItemIcon>
      {isDrawerOpen && (
        <ListItemText
          classes={{ primary: classes.title }}
          primary={item.title}
          style={{ fontWeight: "400", fontFamily: "AvenirLT" }}
        />
      )}
      {isDrawerOpen && NavIconBeta && (
        <ListItemIcon
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            minWidth: "40px",
          }}>
          <span
            className={
              item.href === hrefText && !isDrawerOpen
                ? clsx(classes.icon, "active")
                : clsx(classes.icon)
            }>
            <NavIconBeta
              color={item.href === hrefText ? "#051d33" : "#004C60"}
              width={36}
              height={18}
            />
          </span>
        </ListItemIcon>
      )}
    </ListItem>
  );
};
NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
