import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar, Button, Grid, Typography } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import MaskedInput from "react-text-mask";
import styles from "../../../../styles/index";
import {
  RightDrawer,
  InputText,
  SingleSelect,
  MultiSelectChip,
  AlertDialogDelete,
} from "../../../../components";
import { editPrinter, deletePrinters } from "../../../../redux/actions";
import MyContext from "../../../../Provider/MyContext";
import { H4, AvenirBlackH4, H8, H5, AvenirRedH4 } from "../../../../utils/text";
import {
  Delete as DeleteIcon,
  Settings as SettingIcon,
  CloseCircle as CircleClose,
  ExclamationIcon,
} from "../../../../components/SVG";

import { validateAlphaNumeric } from "../../../../utils/util";

const EditPrinter = ({
  open,
  row,
  callback,
  onClose,
  printers,
  deleteCallback,
}) => {
  const style = styles();
  const Context = useContext(MyContext);
  const unassignedKitchens = useSelector(
    (state) => state.metaReducer.printerUnassigned
  );
  const [kitchens, setKitchens] = useState([]);
  const [kitchensArray, setKitchensArray] = useState([]);
  const [name, setName] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [pModel, setPmodel] = useState(0);
  const printerModel = [{ name: "Star TSP100" }, { name: "Star SP700" }];
  const [printerId, setPrinterId] = useState("");
  const [error, setError] = useState(null);
  const [ipAddresserror, setIpAddresserror] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const alertData = {
    open: false,
    severity: "success",
    msg: "",
  };
  const isDeleteError = false;
  const deleteHeading = Context.langData.delete_printer;
  const [deleteBodyMessage, setDeleteBodyMessage] = useState(
    Context.langData.printer_body_delete
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (row) {
      setPrinterId(row.id);
      setName(row.name);
      setIpAddress(row.ip_address);
      setPmodel(row.printer_model);
      if (row.assigned_kitchens) {
        setDeleteBodyMessage(Context.langData.printer_body_delete_warning);
        setKitchens([...row.assigned_kitchens, ...unassignedKitchens]);
        setKitchensArray(row.assigned_kitchens.map((d) => d.id));
      } else {
        setKitchens([...unassignedKitchens]);
      }
    }
  }, [row]);
  useEffect(() => {}, [kitchensArray]);
  const save = () => {
    let errorFlag = 0;
    const kitchenIds = [];
    if (!name) {
      errorFlag += 1;
      setError(Context.langData.no_printer_error);
    }
    if (ipAddress) {
      const regex =
        /^((?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])[.]){3}(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$/;
      const m = regex.test(ipAddress);
      if (!m) {
        errorFlag += 1;
        setIpAddresserror(Context.langData.ip_address_error);
      } else {
        setIpAddresserror("");
      }
    } else {
      errorFlag += 1;
      setIpAddresserror(Context.langData.enter_ipaddress_error);
    }
    const isArray = printers.filter((e) => e.id !== printerId);
    const elementsIndex = isArray.findIndex(
      (indexElement) => indexElement.name === name
    );
    if (elementsIndex !== -1) {
      errorFlag += 1;
      setError(`${name} already exist`);
    }
    const elementsipIndex = isArray.findIndex(
      (indexElement) => indexElement.ip_address === ipAddress
    );
    if (elementsipIndex !== -1) {
      errorFlag += 1;
      setIpAddresserror(`${ipAddress} already exist`);
    }
    if (errorFlag === 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const i of kitchensArray) {
        kitchenIds.push({
          kitchen_id: i,
        });
      }
      if (kitchenIds.length > 0) {
        dispatch(
          editPrinter(
            {
              printer_id: printerId,
              ip_address: ipAddress.trim(),
              name: name.trim(),
              printer_model: pModel,
              kitchens: kitchenIds,
            },
            callback
          )
        );
      } else {
        dispatch(
          editPrinter(
            {
              printer_id: printerId,
              ip_address: ipAddress.trim(),
              name: name.trim(),
              printer_model: pModel,
              kitchens: [],
            },
            callback
          )
        );
      }
    }
  };
  const handleMultiSelect = (e) => {
    setKitchensArray(e);
  };
  const onChange = (val) => {
    setName(validateAlphaNumeric(val));
    if (printers.indexOf(val.toLowerCase()) === -1) {
      setError("");
    } else {
      setError(`${validateAlphaNumeric(val)} already exist`);
    }
  };

  const props = {
    guide: false,
    placeholderChar: "\u2000",
    mask: (value) => Array(value.length).fill(/[\d.]/),
    pipe: (value) => {
      if (value === "." || value.endsWith("..")) return false;

      const parts = value.split(".");
      if (
        parts.length > 4 ||
        parts.some((part) => part === "00" || part < 0 || part > 255)
      ) {
        return false;
      }
      return value;
    },
  };

  const handleClose = (val) => {
    if (val) {
      dispatch(deletePrinters([row.id], deleteCallback));
    }
    setShowDialog(false);
  };

  const handleDelete = () => {
    setShowDialog(true);
  };

  const onClear = () => {
    setKitchensArray([]);
  };

  return (
    <RightDrawer
      data-qaid='createcomment_centermodal'
      open={open}
      onClose={onClose}
      padding={1}>
      <Grid
        style={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
        }}>
        <Grid
          style={{
            display: "flex",
            height: "80%",
            flexDirection: "column",
            overflowX: "auto",
          }}>
          <Grid
            data-qaid='createcategory_close_grid'
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}>
            <CircleClose
              data-qaid='createcategory_closeicon'
              size={20}
              onClick={onClose}
              style={{ marginRight: 40, marginTop: 20 }}
            />
          </Grid>
          <Grid
            data-qaid='createcategory_settings_grid'
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginLeft: 56,
              marginBottom: 10,
            }}>
            <SettingIcon
              data-qaid='createcategory_setting_icon'
              style={{ marginRight: 10 }}
              width={22.5}
              height={24}
              color='#707272'
            />
            <H4
              data-qaid='createcategory_settings'
              label={Context.langData.settings}
              style={{ color: "#707272" }}
            />
          </Grid>
          <H8
            data-qaid='createcategory_title'
            style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }}
            label={Context.langData.edit_printer}
          />
          <Grid
            container
            direction='column'
            style={{
              marginLeft: 56,
              marginBottom: 20,
              marginRight: 56,
              width: 500,
            }}>
            <Grid
              data-qaid='editPrinter_name_grid'
              style={{ display: "flex", flexDirection: "column" }}>
              <AvenirBlackH4
                data-qaid='editPrinter_namelabel'
                label={Context.langData.printer_name}
                style={{ marginBottom: 12 }}
              />
              <InputText
                data-qaid='editPrinter_nameinput'
                value={name}
                error={error}
                maxLength={64}
                onChange={(val) => onChange(val.target.value)}
                id='name'
                inputProps={{ maxLength: 64 }}
              />
              <Grid
                item
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  marginTop: 10,
                  display: "flex",
                  paddingTop: 5,
                }}>
                <Grid
                  item
                  xs={9}
                  style={{
                    flexDirection: "row",
                    alignItems: "flex-start",
                    display: "flex",
                  }}>
                  <H5
                    label={
                      Context.langData
                        .name_the_printer_after_the_kitchen_station
                    }
                    style={{ paddingLeft: 5 }}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                  }}>
                  <H5
                    style={{ textAlign: "right" }}
                    label={
                      name?.length
                        ? `${name?.length} ${
                            Context.langData.characters_count_64
                          }`
                        : "0"
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid style={{ width: "55%" }}>
              <AvenirBlackH4
                data-qaid=''
                label={Context.langData.printer_model}
                style={{ marginBottom: 5 }}
              />
              <SingleSelect
                list={printerModel}
                valueKey='name'
                displayKey='name'
                value={pModel}
                handleChange={(e) => setPmodel(e.target.value)}
                border={false}
                height={54}
                FormWidth='100%'
                borderStyle='1px solid #707272'
              />
            </Grid>
            <Grid
              data-qaid='editPrinter_ipgrid'
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 16,
              }}>
              <AvenirBlackH4
                data-qaid='editPrinter_iplabel'
                label={Context.langData.ip_address}
                style={{ marginBottom: 12 }}
              />
              <MaskedInput
                {...props}
                style={{
                  height: 56,
                  color: "#051D33",
                  borderRadius: 4,
                  paddingLeft: 10,
                  border: ipAddresserror
                    ? "1px solid #AB1212"
                    : "1px solid #707272",
                  fontSize: 16,
                  fontFamily: "AvenirLT",
                }}
                value={ipAddress}
                onChange={(val) => setIpAddress(val.target.value)}
              />
              {ipAddresserror && (
                <Grid
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    display: "flex",
                    marginTop: 5,
                  }}>
                  <ExclamationIcon color='#AB1212' style={{ marginRight: 5 }} />
                  <H5 label={ipAddresserror} style={{ color: "#AB1212 " }} />
                </Grid>
              )}
              <Grid
                style={{
                  flexDirection: "row",
                  alignItems: "flex-start",
                  display: "flex",
                  paddingTop: 10,
                }}>
                <H5
                  label={Context.langData.enter_the_ipAddress}
                  style={{ paddingLeft: 5 }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction='column'
              data-qaid='assignPrinter_kitchenlistGrid'
              style={{ width: "100%", marginTop: 10, marginBottom: 20 }}>
              <Grid
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 10,
                }}>
                <AvenirBlackH4
                  data-qaid='assignPrinter_kitchenLabel'
                  label={Context.langData.assigned_kitchens}
                  style={{ marginBottom: 5 }}
                />
                {kitchensArray.length > 0 && (
                  <Button
                    className={style.buttonClass}
                    data-qaid='clearLabel'
                    style={{
                      backgroundColor: "white",
                      width: "5%",
                      marginRight: 5,
                    }}
                    variant='label'
                    onClick={onClear}>
                    <AvenirBlackH4
                      data-qaid='clearLabel'
                      label={Context.langData.clear}
                      style={{
                        color: "#4169E1",
                        backgroundColor: "white",
                      }}
                    />
                  </Button>
                )}
              </Grid>

              <MultiSelectChip
                disableMultiChip={pModel === "Star TSP100"}
                list={kitchens}
                value='id'
                name='name'
                placeholder={Context.langData.select_kitchen}
                selectedValue={kitchensArray}
                handleChange={handleMultiSelect}
                border={false}
                height={56}
                width={498}
                tooltipMessage='Kitchen printing is only currently supported for Star SP700 printers.'
              />
              {kitchens.length === 0 && (
                <Grid
                  style={{
                    flexDirection: "row",
                    alignItems: "flex-start",
                    display: "flex",
                    paddingTop: 10,
                  }}>
                  <H5 label='No kitchen available' style={{ paddingLeft: 5 }} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{
            display: "flex",
            height: "20%",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}>
          <Grid container style={{ backgroundColor: "#FAFAFA" }}>
            <Grid
              data-qaid='editPrinter_cancel_btn'
              item
              xs={12}
              lg={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                // justifyContent: "flex-end",
                marginTop: 32,
                marginBottom: 32,
              }}>
              <Grid container wrap='nowrap' style={{ width: "100%" }}>
                <Grid container>
                  <Button
                    className={style.buttonClass}
                    data-qaid='shifts_delete_button'
                    style={{ marginRight: 10 }}
                    variant='text'
                    id='cancel'
                    onClick={handleDelete}>
                    <DeleteIcon
                      data-qaid='shifts_deleteicon'
                      width={22}
                      height={22}
                      color='#8b0000'
                      style={{ marginLeft: 45 }}
                    />
                    <AvenirRedH4
                      data-qaid='shifts_delete_label'
                      style={{ color: "#8b0000", marginLeft: 5 }}
                      label={Context.langData.delete}
                    />
                  </Button>
                </Grid>
              </Grid>
              <Button
                className={style.buttonClass}
                data-qaid='editPrinter_cancel_button'
                onClick={onClose}
                variant='contained'
                style={{
                  backgroundColor: "#FFF",
                  marginRight: 16,
                  border: "2px solid #051D33",
                  height: 40,
                  width: 103,
                }}>
                <AvenirBlackH4
                  data-qaid='editPrinter_cancel_text'
                  label={Context.langData.cancel}
                />
              </Button>
              <Button
                className={style.buttonClass}
                data-qaid='editPrinter_save_btn'
                variant='contained'
                style={{
                  backgroundColor: "#FFB600",
                  marginRight: 32,
                  height: 40,
                  width: 90,
                }}
                onClick={save}>
                <AvenirBlackH4
                  data-qaid='editPrinter_save_text'
                  label={Context.langData.save}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <AlertDialogDelete
        data-qaid='offersList_alertdialog'
        showDialog={showDialog}
        body={deleteBodyMessage}
        heading={deleteHeading}
        isDeleteError={isDeleteError}
        handleClose={handleClose}
        showExtra={false}
        buttonLabel={Context.langData.delete}
      />
      <Message
        open={alertData.open}
        severity={alertData.severity}
        msg={alertData.msg}
        handleClose={() => {}}
      />
    </RightDrawer>
  );
};
const Message = ({ open, handleClose, severity, msg }) => (
  <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
    <Alert onClose={handleClose} severity={severity}>
      <Typography variant='h5'>{msg}</Typography>
    </Alert>
  </Snackbar>
);
const Alert = (props) => <MuiAlert elevation={6} variant='filled' {...props} />;

export default EditPrinter;
