import React, { useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { Grid } from "@material-ui/core";
import {
  fetchOrderTypes,
  fetchPaymentTypes,
  fetchTaxes,
  fetchOrderTypeDelivery,
  fetchDietaryAttributes,
} from "../../../redux/actions";
import { RoutedVerticalTabs } from "../../../components";
import MyContext from "../../../Provider/MyContext";

const OrderTypes = () => {
  const dispatch = useDispatch();
  const Context = useContext(MyContext);
  const navigate = useNavigate();
  const location = useLocation();

  const Tabs = [
    {
      name: Context.langData.item_catalogue,
      path: "item-catalogue",
    },
    {
      name: Context.langData.order_types,
      path: "order-type-list",
    },
    {
      name: Context.langData.modifiers,
      path: "modifiers",
    },
    {
      name: Context.langData.dietary_attributes,
      path: "dietary-attributes",
    },
  ];

  useEffect(() => {
    // Dispatch actions to fetch necessary data
    dispatch(fetchOrderTypes());
    dispatch(fetchPaymentTypes());
    dispatch(fetchTaxes());
    dispatch(fetchOrderTypeDelivery());
    dispatch(fetchDietaryAttributes());
  }, [dispatch]);

  const handleTabChange = (index) => {
    navigate(`/home/menu/settings/${Tabs[index].path}`);
  };

  return (
    <div style={{ borderTop: "1px solid #EEEEEE" }}>
      <Grid container direction='row' style={{ margin: 20 }}>
        {/* Vertical Tabs */}
        <Grid item xs={12} lg={2} style={{ backgroundColor: "#FAFAFA" }}>
          <RoutedVerticalTabs
            tabData={Tabs}
            indexCallback={handleTabChange}
            selectedIndex={Tabs.findIndex((tab) =>
              location.pathname.includes(tab.path)
            )}
            style={{ marginTop: 24 }}
          />
        </Grid>

        <Grid item xs={12} lg={10}>
          <Outlet />
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderTypes;
